/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */

// Test Quiz Api

import { KeyedMutator } from 'swr';
import { DataPaylaod } from './useRequest.types';

export type SubSection = {
  _id: string;
  name: string;
};

interface IQuizOption {
  name: string;
  isCorrect: boolean;
}

interface IQuizQuestion {
  title: string;
  options: IQuizOption[];
}

export interface IDoubtReport {
  doubtId: string;
  userId: string;
  courseId: string;
  lessonId: string;
  reportType: Array<string>;
  text: string;
}

export enum ReportType {
  CONTENT_IS_IRRELEVANT = 'content_is_irrelevant',
  CONTENT_IS_AMBIGUOUS = 'content_is_ambiguous',
  CONTENT_IS_SUSPICIOUS = 'content_is_suspicious',
  OTHER = 'other',
}

export enum ClientType {
  BASE = 'base',
  PENPENCIL = 'penPencil',
}

export interface doubtReportSchema {
  doubtId: string;
  userId: string;
  courseId: string;
  lessonId: string;
  reportType: [ReportType];
  text: string;
}

export interface mcqQuestionOptions {
  body: {
    type: string;
    required: true;
  };
  isCorrect: {
    type: boolean;
    required: true;
    default: false;
  };
}

export interface mcqQuestionSolution {
  text: string;
  video?: string;
}

export enum QuestionTypeEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

enum DifficultyLevelEnum {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

enum McqTypeEnum {
  PRACTICE_TEST = 'practice_test',
  LIVE_TEST = 'live_test',
  QUIZ = 'quiz',
  CODING_PRACTICE_TEST = 'coding_practice_test',
  CODING_LIVE_TEST = 'coding_live_test',
  CODING_QUIZ = 'coding_quiz',
}

export const CodingMcqTypeArray = ['coding_live_test', 'coding_practice_test', 'coding_quiz'];

export enum McqStateEnum {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum AvailableForEnum {
  WEB = 'web',
  MOBILE = 'mobile',
  WEB_AND_MOBILE = 'web_and_mobile',
}

export interface mcqQuestionSchema {
  question: string;
  encodedQuestion: string;
  options: [mcqQuestionOptions];
  questionScore: number;
  questionType: QuestionTypeEnum;
  difficultyLevel: DifficultyLevelEnum;
  subSection: string;
  solution: mcqQuestionSolution;
  negativeMark: number;
}

export interface mcqDetail {
  noOfAttempts: number;
  maxScore: number;
  mcqType: McqTypeEnum;
  instructions: string;
  questions: [mcqQuestionSchema];
  subSections: [SubSection];
  shuffleQuestions: boolean;
  mcqState: McqStateEnum;
  availableFor: AvailableForEnum;
  passPercentage: number;
}

export enum LessonTypes {
  VIDEO = 'video',
  ASSIGNMENT = 'assignment',
  SECTION_RESOURCE = 'sectionResource',
  QUIZ = 'quiz',
  NOTES = 'notes',
  TEST = 'test',
  CODING_ASSESSMENT = 'coding_assessment',
  CODING_QUIZ = 'coding_quiz',
  PROJECT = 'project',
  ASSIGNMENT_V2 = 'assignment_v2',
  QUIZ_V2 = 'quiz_v2',
}
export enum LectureModeEnum {
  RECORDED = 'recorded',
  LIVE = 'live',
  SESSION = 'session',
}
export enum LessonStatus {
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
  SHOW_LOCKED = 'showLocked',
}

export interface IResource {
  title: string;
  url: string;
}
export interface IInstruction {
  beforeStart: string;
  afterStart: string;
}
export interface IMcqQuestionOption {
  _id: string;
  body: string;
  isCorrect: boolean;
}
export interface IMcqQuestionSolution {
  text: string;
  video?: string;
}
export interface IMcqQuestion {
  question?: string;
  title?: string;
  encodedQuestion?: string;
  options?: IMcqQuestionOption[];
  questionScore: number;
  questionType?: QuestionTypeEnum;
  difficultyLevel?: DifficultyLevelEnum;
  // subSection: string;
  subSectionId: string;
  subSectionName?: string;
  solution?: IMcqQuestionSolution;
  negativeMark?: number;
  _id: string;
  problemWidgetHash?: string;
  solutionWidgetHash?: string;
  lang?: number;
  code?: string;
  solutions?: codingSolutionType[];
}

export interface IMcqDetails {
  noOfAttempts: number;
  maxScore: number;
  mcqType: McqTypeEnum;
  instructions: string;
  questions: IMcqQuestion[];
  subSections: SubSection[];
  shuffleQuestions: boolean;
  mcqState: McqStateEnum;
  availableFor: AvailableForEnum;
  passPercentage: number;
  type?: LessonTypes;
  title?: string;
  codingQuestions?: codingSolutionType[];
}

export interface ILesson {
  _id: string;
  title: string;
  description: string;
  userId: string;
  courseId: string;
  milestoneId: string;
  batchId?: string;
  type: LessonTypes;
  isCompleted: boolean;
  remindedLesson: boolean;
  lectureMode: LectureModeEnum;
  status: LessonStatus;
  mentors: string[];
  startDate: string;
  endDate: string;
  overview: string;
  attachment: string[];
  code: string;
  notesToggle: boolean;
  feedbackToggle: boolean;
  doubtsToggle: boolean;
  isMandatory: boolean;
  enableNotification: boolean;
  data: {
    provider: string;
    url: string;
    maxPoints: number;
    thumbnail: string;
    duration: number;
    resourceURL: string;
  };
  resources: IResource[];
  quizQuestions: IQuizQuestion[];
  mcqDetail: IMcqDetails;
}

// Api type for TestQuizStart

interface McqInfoType {
  lesson: {
    title: string;
    type: LessonTypes;
    status: LessonStatus;
    isMandatory: boolean;
    _id: string;
    startDate: string;
    endDate: string;
    description: string;
    mcqDetail?: {
      mcqState: McqStateEnum;
      maxScore: number;
      instructions: {
        beforeStart: string;
        afterStart: string;
      };
      availableFor: AvailableForEnum;
      mcqType?: string;
      noOfReAttempts: number;
    };
  };
  McqTestStatus?: {
    attemptNumber: number;
    testStatus: McqTestStatus;
  };
}

export interface McqInfoSwrResponse {
  data?: {
    data: McqInfoType;
    time_now: string;
  };
  error?: string;
  isLoading: boolean;
  isValidating: boolean;

  mutate: KeyedMutator<DataPaylaod<McqInfoType>>;
}

export interface IMcqInfo {
  data: Data;
  success: boolean;
  time_now: string;
}

export interface Data {
  lesson: Lesson;
  McqTestStatus: IMcqTestStatus;
}

export interface Lesson {
  data: Data2;
  title: string;
  type: LessonTypes;
  status: string;
  mentors: any[];
  attachment: any[];
  notesToggle: boolean;
  feedbackToggle: boolean;
  doubtsToggle: boolean;
  isMandatory: boolean;
  enableNotification: boolean;
  _id: string;
  overviewToggle: boolean;
  attachmentToggle: boolean;
  codeToggle: boolean;
  courseId: string;
  description: string;
  startDate: string;
  endDate: string;
  mcqDetail: McqDetail;
  resources: any[];
  codingTemplatesForCompilerWidgets: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  deadlineReminderDate: any;
  milestoneId: any;
  notification: Notification;
}

export interface Data2 {
  duration: number;
}

export interface McqDetail {
  noOfReAttempts: number;
  maxStartDuration: number;
  shuffleQuestions: boolean;
  mcqState: string;
  _id: string;
  subSections: ISubSection[];
  maxScore: number;
  mcqType: string;
  instructions: string;
  availableFor: string;
  passPercentage: number;
}

export interface ISubSection {
  _id: string;
  name: string;
}

export interface Notification {
  notifyLearners: boolean;
  type: string;
  _id: string;
}

export interface IMcqTestStatus {
  attemptNumber: number;
  testStatus: McqTestStatus;
}

// Api type for Start or Resume Test/Quiz

export enum McqTestStatus {
  ACTIVE = 'active',
  SUBMIT = 'submit',
  AUTOSUBMIT = 'auto_submit',
  PAUSE = 'pause',
  EVALUATED = 'evaluated',
  MARKS_ANNOUNCED = 'marks_announced',
}
export enum AnswerStatus {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  PARTIALLY_CORRECT = 'partially_correct',
  NOT_ATTEMPTED = 'not_attempted',
  SELECTED = 'selected',
  REVIEW = 'review',
  ATTEMPTED_AND_REVIEW = 'attempted_and_review',
  ATTEMPTED = 'attempted',
  NULL = 'null',
}

export interface IAnswers {
  answer?: string[];
  questionId: string;
  marks?: number;
  answerStatus?: AnswerStatus;
  subSection?: string;
}
export interface IAnalytics {
  subSectionId: string;
  subSectionName: string;
  sectionMaxScore: number;
  correctAnswers: number;
  correctAnswersMarks: number;
  incorrectAnswers: number;
  incorrectAnswersMarks: number;
  notAttempted: number;
  notAttemptedMarks: number;
  accuracy: number;
  completion: number;
  partiallyCorrect: number;
  partiallyCorrectMarks: number;
}
export interface IMcqResponses {
  userId: string;
  lessonId: string;
  attemptNumber: number;
  tabSwitchCounter: number;
  totalScore: number;
  answers: IAnswers[];
  testStatus: McqTestStatus;
  timeRemaining: number;
  currentStartTime: Date;
  endTime: Date;
  createdAt: Date;
  isAutoSubmitted: boolean;
  isTimeLimited: boolean;
  analytics: IAnalytics[];
  timeUsed?: number;
}

interface StartorResumeType {
  lessonInfo: ILesson;
  responseInfo: IMcqResponses;
  status: string;
}

export interface StartorResumeSwrResponse {
  data?: {
    data: StartorResumeType;
  };
  error?: { error: string };
  isLoading: boolean;
  isValidating: boolean;
  mutate: KeyedMutator<DataPaylaod<McqInfoType>>;
}

export type submitTestData = {
  questionId: string;
  answer: string[];
}[];

export type submitQuizData = {
  questionId: string;
  subSection: string;
  answer: string[];
}[];

export interface submitOption extends IMcqQuestionOption {
  isSelected: boolean;
}

// quiz submit repsonse type
export type codingSolutionType = {
  _id: string;
  language: string;
  languageCode: number;
  code: string;
  video: string;
};
export type quizSubmitResponse = {
  answerStatus: AnswerStatus;
  negativeMark: number;
  options: Array<submitOption>;
  questionScore: number;
  solution?: IMcqQuestionSolution;
  subSection: string;
  title: string;
  _id: string;
  codingSolution?: codingSolutionType[];
};

// Analytics

export interface ISectionWiseAnalyticsData {
  attemptNumber: number;
  analytics: IAnalytics[];
  endTime: string;
  startedAt: string;
  isLive?: boolean;
  timeUsed?: number;
  accuracy?: number;
  createdAt?: string;
}

export type ISectionWiseAnalyticsDataSWRData = ISectionWiseAnalyticsData[] | undefined;

// Leaderboard

export interface ILeaderBoardData {
  usersRank: IFRRanking;
  topFifty: IFRRanking[];
  speed: number;
  accuracy: number;
}

export interface IFRRanking {
  rank: number;
  createdAt: string;
  endTime: string;
  totalScore: number;
  time: string | undefined;
  userId: {
    _id: string;
    firstName: string;
    img: {
      link: string;
      source: string;
    };
  };
}

export interface comparativeAnalysisForLiveTestFunctionResult {
  topper?: number;
  user?: number;
  average?: number;
}

export interface averageAnalysisResponse {
  id: string | null;
  average: number;
}

export interface AgarData {
  accuracy: number;
  completion: number;
  marks: number;
}

export interface IcodeSubmission {
  answerStatus: string;
  _id: string;
  problemId: string;
  subSectionId: string;
  language: number;
  body: string;
  sphereEngineScore: number;
  marks: number;
}

export const enum HelpCentreToggle {
  true = 'true',
  false = 'false',
}
