/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum PaymentTypes {
  PAYMENT_FAILED = 'payment_failed',
  GATEWAY_ERROR = 'GATEWAY_ERROR',
  PAYMENT_DESCRIPTION_FAILED = 'Payment failed',
}
export enum BooleanValue {
  TRUE = 'true',
  FALSE = 'false',
}
export enum LoginType {
  COOKIE = 'cookie',
}
export enum MiscellaneousEnums {
  LOGGED_IN = 'loggedIn',
}
export enum RouterEnmus {
  CATEGORY = 'category',
  COURSE = 'course',
  CATEGORY_SLUG = 'categorySlug',
  COURSE_SLUG = 'courseSlug',
}
export enum KaptureEnums {
  KAPTURE_CC = 'kaptureCC',
  KAPTURE_IV = 'kaptureIV',
}

export enum Environment {
  DEVELOPMENT = 'dev',
  PRODUCTION = 'prod',
  STAGING = 'staging',
}
export enum CurrentDetailsEnums {
  CURRENT_COURSE = 'currentCourse',
}
